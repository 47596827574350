import React from 'react'
import { Navigate } from 'react-router-dom'
import { path } from '../constants/path'

function AuthenticatedGuard({ children }) {
  const authenticated = !!localStorage.getItem('ACCESS_TOKEN')

  if (!authenticated) {
    return <Navigate to={path.login} />
  }
  return <>{children}</>
}

export default AuthenticatedGuard
