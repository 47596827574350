const listNumberOfBills = [
  { name: '10.000 VNĐ', value: 10000 },
  { name: '20.000 VNĐ', value: 20000 },
  { name: '50.000 VNĐ', value: 50000 },
  { name: '100.000 VNĐ', value: 100000 },
  { name: '200.000 VNĐ', value: 200000 },
  { name: '500.000 VNĐ', value: 500000 }
]

const convertToShortFormat = (number) => {
  return number / 1000 + 'k'
}

export { listNumberOfBills, convertToShortFormat }
