import { createContext, useContext, useEffect, useState } from 'react'

export const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  useEffect(() => {
    const isAuthenticated = !!localStorage.getItem('ACCESS_TOKEN')
    setAuthenticated(isAuthenticated)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        openDrawer,
        setOpenDrawer,
        toggleDrawer
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
