import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const Filter = ({
  filterTransaction,
  onChangeFilterTransaction,
  onResetFilter,
  listKiosk
}) => {
  return (
    <div className="card-toolbar my-1 d-flex align-items-center justify-content-center filter-container">
      <div className="me-5 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Tên máy Kiosk:
        </span>
        <select
          id="kt_filter_orders"
          name="orders"
          data-control="select2"
          data-hide-search="true"
          className="w-125px form-select form-select-solid form-select-sm"
          value={filterTransaction.kioskId}
          onChange={(event) =>
            onChangeFilterTransaction({
              kioskId: event.target.value
            })
          }
        >
          <option value="" selected="selected">
            Tất cả
          </option>
          {listKiosk.map((kiosk) => (
            <option value={kiosk.kioskId}>{kiosk.kioskName}</option>
          ))}
        </select>
      </div>

      <div className="me-4 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Phương thức thanh toán:
        </span>

        <select
          id="kt_filter_orders"
          name="orders"
          data-control="select2"
          data-hide-search="true"
          className="w-125px form-select form-select-solid form-select-sm"
          value={filterTransaction.paymentMethod}
          onChange={(event) =>
            onChangeFilterTransaction({
              paymentMethod: event.target.value
            })
          }
        >
          <option value="" selected="selected">
            Tất cả
          </option>
          <option value="CASH">TIỀN MẶT</option>
          <option value="VNPAY">VNPAY</option>
          <option value="MPOS">MPOS</option>
          <option value="MOMO">MOMO</option>
        </select>
      </div>

      <div className="me-4 my-1 d-flex align-items-center filter-item">
        <span style={{ fontSize: '12px', marginRight: 10 }}>
          Ngày giao dịch:
        </span>
        <DatePicker
          className="w-125px"
          dateFormat="dd/MM/yyyy"
          selected={filterTransaction.startDate}
          onChange={(date) =>
            onChangeFilterTransaction({
              startDate: date
            })
          }
        />
      </div>

      <img
        onClick={onResetFilter}
        className="cursor-pointer filter-reset"
        src="/assets/images/reset.png"
        alt="lisence-plate"
        width={30}
      />
    </div>
  )
}

export default Filter
