import React, { useEffect, useState } from 'react'

const Modal = ({ show, title, handleClose, handleConfirm }) => {
  const [kioskInfo, setKioskInfo] = useState({})

  useEffect(() => {
    setKioskInfo({ kioskId: '', kioskName: '' })
  }, [show])

  return (
    <div
      className={`modal fade modal-open ${show ? 'show d-block' : 'd-none'}`}
      tabIndex={-1}
      style={{ backgroundColor: '#0aefd !important' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <div
              onClick={handleClose}
              className="svg-icon svg-icon-1 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x={6}
                  y="17.3137"
                  width={16}
                  height={2}
                  rx={1}
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y={6}
                  width={16}
                  height={2}
                  rx={1}
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex align-items-center mb-5" style={{ flex: 1 }}>
              <label className="me-5 text-center" style={{ width: '30%' }}>
                <span style={{ color: 'red' }}>*</span> ID Kiosk
              </label>
              <input
                style={{ fontSize: '12px' }}
                type="text"
                className="form-control form-control-lg form-control-solid"
                value={kioskInfo?.kioskId}
                onChange={(e) => {
                  setKioskInfo({
                    ...kioskInfo,
                    kioskId: e.target.value
                  })
                }}
              />
            </div>

            <div className="d-flex align-items-center" style={{ flex: 1 }}>
              <label className="me-5 text-center" style={{ width: '30%' }}>
                <span style={{ color: 'red' }}>*</span> Tên Kiosk
              </label>
              <input
                style={{ fontSize: '12px' }}
                type="text"
                className="form-control form-control-lg form-control-solid"
                value={kioskInfo?.kioskName}
                onChange={(e) => {
                  setKioskInfo({
                    ...kioskInfo,
                    kioskName: e.target.value
                  })
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold"
              data-dismiss="modal"
              onClick={handleClose}
            >
              Đóng
            </button>
            <button
              onClick={() => handleConfirm(kioskInfo)}
              type="button"
              className="btn btn-primary font-weight-bold"
            >
              Tạo
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
