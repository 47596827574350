import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { convertToShortFormat, listNumberOfBills } from '../../constants/common'
import { formatCurrencyVnd } from '../../utils'
import http from '../../utils/http'
import { toast } from 'react-toastify'
import Modal from '../Modal/Modal'

const Setting = () => {
  const [settings, setSettings] = useState([])

  const [showModalCreate, setShowModalCreate] = useState(false)

  const [settingSelected, setSettingSelected] = useState({})

  const [showMinBill, setShowMinBill] = useState(false)
  const [showMaxBill, setShowMaxBill] = useState(false)
  const [showReturnBill, setShowReturnBill] = useState(false)
  const [showEnabledTypeMoney, setShowEnabledTypeMoney] = useState(false)

  const handleCloseModal = () => setShowModalCreate(false)
  const handleCreateKiosk = async (kioskInfo) => {
    try {
      await http.post('/settings', {
        ...kioskInfo,
        numberOfBill: {
          '10k': 0,
          '20k': 0,
          '50k': 0,
          '100k': 0,
          '200k': 0,
          '500k': 0
        },
        enabledAmountOfMoney: '10k'
      })
      getSettings()
      setShowModalCreate(false)
    } catch (error) {
      const errorMessage = error.message
      toast.error(errorMessage.replace('Error: ', ''), {
        position: 'top-center',
        autoClose: 3000
      })
    }
  }

  const handleSaveSetting = async (id) => {
    try {
      const newSettingSelected = JSON.parse(JSON.stringify(settingSelected))
      delete newSettingSelected._id

      await http.put(`/settings/${id}`, newSettingSelected)
      toast.success('Cập nhật thành công', {
        position: 'top-center',
        autoClose: 3000
      })
    } catch (error) {
      toast.error('Cập nhật thất bại', {
        position: 'top-center',
        autoClose: 3000
      })
    }
  }

  const getSettings = async () => {
    try {
      const settingload = sessionStorage.getItem('settingload')
      if (settingload == null && settingload !== 0) {
        sessionStorage.setItem('settingload', '0')

        const kiosktotal = sessionStorage.getItem('kioskTotal')

        const response = await http.get('/settings')
        if (response.data.length > 0) {
          for (var i = 0; i < kiosktotal; i++) {
            const kioskid = sessionStorage.getItem('kiosk' + i)
            setSettings((prev) => [
              ...prev,
              response.data.find((kiosk) => kiosk._id === kioskid)
            ])
            if (i === 0)
              setSettingSelected(
                response.data.find((kiosk) => kiosk._id === kioskid)
              )
          }
        }
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    if (settings.length !== 0) {
      sessionStorage.removeItem('settingload')
    }
  })

  return (
    <div style={{ padding: '20px' }}>
      <div
        id="kt_app_content_container"
        className="app-container  container-xxl"
      >
        <div className="card mb-5 mb-xxl-8">
          <div className="card-body pb-0 pt-0 d-flex align-items-center justify-content-between">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              {settings.map((setting) => (
                <li
                  className="nav-item mt-2"
                  onClick={() => {
                    setSettingSelected(setting)
                  }}
                >
                  <Link
                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                    className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                      settingSelected.kioskId === setting.kioskId
                        ? 'active'
                        : ''
                    }`}
                    to={'#'}
                  >
                    {setting?.kioskName || ''}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row g-5 g-xxl-8">
          <div className="col-xl-12">
            <div className="card mb-5 mb-xxl-8">
              <div className="card-body pb-0">
                <div>
                  <div>
                    <h3 className="mb-5">
                      Mã máy Kiosk: {settingSelected.kioskId}
                    </h3>
                    <h3 className="mb-5">Mệnh giá chấp nhận</h3>
                    <div
                      className="d-flex align-items-centers"
                      style={{ gap: 20 }}
                    >
                      <div className="dropdown me-2">
                        <div className="d-flex align-items-center">
                          <span className="font-bold me-2">Min</span>
                          <button
                            className="btn btn-secondary btn-sm dropdown-toggle m-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              setShowMinBill(!showMinBill)
                              setShowMaxBill(false)
                              setShowReturnBill(false)
                            }}
                          >
                            {formatCurrencyVnd(settingSelected.minimumAmount)}
                          </button>
                        </div>

                        <ul
                          style={{ top: '40px', right: 0 }}
                          className={`dropdown-menu ${
                            showMinBill ? 'show' : ''
                          }`}
                          aria-labelledby="dropdownMenuButton"
                        >
                          {listNumberOfBills.map((bill) => (
                            <li
                              className="cursor-pointer"
                              onClick={() => {
                                setSettingSelected({
                                  ...settingSelected,
                                  minimumAmount: bill.value
                                })
                                setShowMinBill(false)
                              }}
                            >
                              <span
                                className={`dropdown-item m-0 ${
                                  Number(bill.value) ===
                                  Number(settingSelected.minimumAmount)
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                {bill.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="dropdown me-5">
                        <div className="d-flex align-items-center">
                          <span className="font-bold me-2">Max</span>
                          <button
                            className="btn btn-secondary btn-sm dropdown-toggle m-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              setShowMaxBill(!showMaxBill)
                              setShowMinBill(false)
                              setShowReturnBill(false)
                            }}
                          >
                            {formatCurrencyVnd(settingSelected.maximumAmount)}
                          </button>
                        </div>

                        <ul
                          style={{ top: '40px', right: 0 }}
                          className={`dropdown-menu ${
                            showMaxBill ? 'show' : ''
                          }`}
                          aria-labelledby="dropdownMenuButton"
                        >
                          {listNumberOfBills.map((bill) => (
                            <li
                              className="cursor-pointer"
                              onClick={() => {
                                setSettingSelected({
                                  ...settingSelected,
                                  maximumAmount: bill.value
                                })
                                setShowMaxBill(false)
                              }}
                            >
                              <span
                                className={`dropdown-item m-0 ${
                                  Number(bill.value) ===
                                  Number(settingSelected.maximumAmount)
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                {bill.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <h3 style={{ marginTop: '30px' }} className="mb-5">
                      Mệnh giá thối
                    </h3>
                    <div
                      className="d-flex align-items-centers"
                      style={{ gap: 20 }}
                    >
                      <div className="dropdown me-2">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-secondary btn-sm dropdown-toggle m-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              setShowReturnBill(!showReturnBill)
                              setShowMinBill(false)
                              setShowMaxBill(false)
                            }}
                          >
                            {formatCurrencyVnd(settingSelected.returnAmount)}
                          </button>
                        </div>

                        <ul
                          style={{ top: '40px', right: 0 }}
                          className={`dropdown-menu ${
                            showReturnBill ? 'show' : ''
                          }`}
                          aria-labelledby="dropdownMenuButton"
                        >
                          {listNumberOfBills.map((bill) => (
                            <li
                              className="cursor-pointer"
                              onClick={() => {
                                setSettingSelected({
                                  ...settingSelected,
                                  returnAmount: bill.value
                                })
                                setShowReturnBill(false)
                              }}
                            >
                              <span
                                className={`dropdown-item m-0 ${
                                  Number(bill.value) ===
                                  Number(settingSelected.returnAmount)
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                {bill.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div
                      style={{ marginTop: '30px' }}
                      className="mb-5 d-flex align-items-center"
                    >
                      <h3 className="me-5">Số tờ tiền trong hộp thối</h3>
                      <div className="me-5">|</div>
                      <div className="d-flex align-items-center">
                        <h3 className="me-5">Loại tiền thối</h3>
                        <div
                          className="d-flex align-items-centers"
                          style={{ gap: 20 }}
                        >
                          <div className="dropdown me-2">
                            <div className="d-flex align-items-center">
                              <button
                                className="btn btn-danger btn-sm dropdown-toggle m-0"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => {
                                  setShowEnabledTypeMoney(!showEnabledTypeMoney)
                                  setShowMinBill(false)
                                  setShowMaxBill(false)
                                  setShowReturnBill(false)
                                }}
                              >
                                {formatCurrencyVnd(
                                  Number(
                                    settingSelected.enabledTypeMoney?.replace(
                                      'k',
                                      '000'
                                    )
                                  )
                                )}
                              </button>
                            </div>

                            <ul
                              style={{ top: '40px', right: 0 }}
                              className={`dropdown-menu ${
                                showEnabledTypeMoney ? 'show' : ''
                              }`}
                              aria-labelledby="dropdownMenuButton"
                            >
                              {listNumberOfBills.map((bill) => (
                                <li
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const value = convertToShortFormat(
                                      bill.value
                                    )

                                    setSettingSelected({
                                      ...settingSelected,
                                      enabledTypeMoney: convertToShortFormat(
                                        bill.value
                                      )
                                    })
                                    setShowEnabledTypeMoney(false)
                                  }}
                                >
                                  <span
                                    className={`dropdown-item m-0 ${
                                      Number(bill.value) ===
                                      Number(
                                        settingSelected.enabledTypeMoney?.replace(
                                          'k',
                                          '000'
                                        )
                                      )
                                        ? 'active'
                                        : ''
                                    }`}
                                  >
                                    {bill.name}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-centers"
                      style={{ gap: 20 }}
                    >
                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            10.000
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="number"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected?.numberOfBill?.['10k'] || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value < 0) return
                            setSettingSelected({
                              ...settingSelected,
                              numberOfBill: {
                                ...settingSelected.numberOfBill,
                                '10k': value
                              }
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            20.000
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected?.numberOfBill?.['20k'] || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value < 0) return
                            setSettingSelected({
                              ...settingSelected,
                              numberOfBill: {
                                ...settingSelected.numberOfBill,
                                '20k': value
                              }
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            50.000
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected?.numberOfBill?.['50k'] || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value < 0) return
                            setSettingSelected({
                              ...settingSelected,
                              numberOfBill: {
                                ...settingSelected.numberOfBill,
                                '50k': value
                              }
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            100.000
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected?.numberOfBill?.['100k'] || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value < 0) return
                            setSettingSelected({
                              ...settingSelected,
                              numberOfBill: {
                                ...settingSelected.numberOfBill,
                                '100k': value
                              }
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            200.000
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected?.numberOfBill?.['200k'] || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value < 0) return
                            setSettingSelected({
                              ...settingSelected,
                              numberOfBill: {
                                ...settingSelected.numberOfBill,
                                '200k': value
                              }
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            500.000
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected?.numberOfBill?.['500k'] || 0}
                          onChange={(e) => {
                            const value = Number(e.target.value)
                            if (value < 0) return
                            setSettingSelected({
                              ...settingSelected,
                              numberOfBill: {
                                ...settingSelected.numberOfBill,
                                '500k': value
                              }
                            })
                          }}
                        />
                      </div>
                    </div>

                    <h3 style={{ marginTop: '30px' }} className="mb-5">
                      Cấu hình thanh toán MPOS
                    </h3>
                    <div
                      className="d-flex align-items-centers"
                      style={{ gap: 20 }}
                    >
                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            URL MPOS
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected.urlMpos}
                          onChange={(e) => {
                            setSettingSelected({
                              ...settingSelected,
                              urlMpos: e.target.value
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            POS ID
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected.posIdMpos}
                          onChange={(e) => {
                            setSettingSelected({
                              ...settingSelected,
                              posIdMpos: e.target.value
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            MERCHANT ID
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected.merchantMPos}
                          onChange={(e) => {
                            setSettingSelected({
                              ...settingSelected,
                              merchantMPos: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                        <span style={{ fontSize: 12 }} className="required">
                          SECRET KEY
                        </span>
                      </label>
                      <input
                        style={{ fontSize: '12px' }}
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder=""
                        value={settingSelected.secretKeyMpos}
                        onChange={(e) => {
                          setSettingSelected({
                            ...settingSelected,
                            secretKeyMpos: e.target.value
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 50 }}>
                  <div>
                    <h3 className="mb-5">Cấu hình thanh toán VNPAY</h3>
                    <div
                      className="d-flex align-items-centers"
                      style={{ gap: 20 }}
                    >
                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            URL VNPAY
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected.urlVnpay}
                          onChange={(e) => {
                            setSettingSelected({
                              ...settingSelected,
                              urlVnpay: e.target.value
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            SECRET KEY
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected.hashSecretVnpay}
                          onChange={(e) => {
                            setSettingSelected({
                              ...settingSelected,
                              hashSecretVnpay: e.target.value
                            })
                          }}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                          <span style={{ fontSize: 12 }} className="required">
                            SECRET KEY NO IPN
                          </span>
                        </label>
                        <input
                          style={{ fontSize: '12px' }}
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={settingSelected.hashSecretNoIpnVnpay}
                          onChange={(e) => {
                            setSettingSelected({
                              ...settingSelected,
                              hashSecretNoIpnVnpay: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-10 mb-10">
                  <button
                    style={{ fontSize: '12px' }}
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    data-kt-stepper-action="next"
                    onClick={() => handleSaveSetting(settingSelected._id)}
                  >
                    CẬP NHẬT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModalCreate}
          handleConfirm={handleCreateKiosk}
          handleClose={handleCloseModal}
          title="TẠO MÁY KIOSK"
        />
      </div>
    </div>
  )
}

export default Setting
