import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { path } from '../constants/path'

function UnauthenticatedGuard({ children }) {
  const authenticated = !!localStorage.getItem('ACCESS_TOKEN')

  if (authenticated) {
    return <Navigate to={path.home} />
  }
  return <>{children}</>
}

UnauthenticatedGuard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
}

export default UnauthenticatedGuard
