import './App.css'
// import Footer from './components/Footer/Footer'
import RoutesPath from './components/Routes/Routes'
import Sidebar from './components/Sidebar/Sidebar'
import { useAuth } from './context/AuthContext'
import React, { useState } from 'react'

function App() {
  
  const { authenticated } = useAuth()
  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        {authenticated && <Sidebar />}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
          style={
            authenticated
              ? { height: '100%', paddingTop: 0 }
              : { height: '100%', paddingTop: 0, paddingLeft: 0 }
          }
        >
          <RoutesPath />
          {/* <Footer /> */}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default App
