import { createDaysInCurrentMonth } from '../utils'

export const dailyChart = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
]

export const weeklyChart = [
  'Thứ hai',
  'Thứ ba',
  'Thứ tư',
  'Thứ năm',
  'Thứ sáu',
  'Thứ bảy',
  'Chủ Nhật'
]

export const monthlyChart = createDaysInCurrentMonth()

export const annualChart = [
  'Tháng 01',
  'Tháng 02',
  'Tháng 03',
  'Tháng 04',
  'Tháng 05',
  'Tháng 06',
  'Tháng 07',
  'Tháng 08',
  'Tháng 09',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12'
]
