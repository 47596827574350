// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Inter' !important;
}

#root {
  height: 100vh;
  overflow: scroll;
}

.aside {
  height: 100vh;
}

.footer {
  margin-top: auto;
}

.col-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16rem;
  padding: 25px 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
}

/* .pagination {
  justify-content: flex-end;
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;;GAEG","sourcesContent":["* {\n  font-family: 'Inter' !important;\n}\n\n#root {\n  height: 100vh;\n  overflow: scroll;\n}\n\n.aside {\n  height: 100vh;\n}\n\n.footer {\n  margin-top: auto;\n}\n\n.col-item {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 16rem;\n  padding: 25px 10px;\n  border-radius: 8px;\n  margin-bottom: 20px;\n  color: #fff;\n  font-size: 16px;\n}\n\n/* .pagination {\n  justify-content: flex-end;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
