const formatCurrency = (amount) => {
  return amount
    .toLocaleString('vi-VN', {
      style: 'currency',
      currency: 'VND'
    })
    .replace('₫', 'VNĐ')
}

const formatCurrencyVnd = (input) => {
  let amount = parseInt(input)

  if (isNaN(amount)) {
    return ''
  }

  let formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  let result = formattedAmount + ' VNĐ'

  return result
}

const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate()
}

const formatDay = (day) => {
  return `Ngày-${day.toString().padStart(2, '0')}`
}

const createDaysInCurrentMonth = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const daysInMonth = getDaysInMonth(currentYear, currentMonth)

  const daysArray = []
  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(formatDay(day))
  }

  return daysArray
}

const formatNumberWithDot = (number) => {
  const numberString = number.toString()
  const firstThreeDigits = numberString.substring(0, 3)
  const restOfDigits = numberString.substring(3)
  const formattedNumber = `${firstThreeDigits}.${restOfDigits}`
  return formattedNumber
}

export {
  formatCurrency,
  createDaysInCurrentMonth,
  formatNumberWithDot,
  formatCurrencyVnd
}
