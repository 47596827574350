import React from 'react'
import { Route, Routes } from 'react-router-dom'
// import Login from './pages/Login'
import { path } from '../../constants/path'
import Transaction from '../Transaction/Transaction'
import Dashboard from '../Dashboard/Dashboard'
import NotFound from '../NotFound/NotFound'
import Login from '../Login/Login'
import AuthenticatedGuard from '../../guard/AuthenticatedGuard'
import UnauthenticatedGuard from '../../guard/UnauthenticatedGuard'
import Setting from '../Setting/Setting'

const RoutesPath = () => {
  return (
    <Routes>
      <Route
        path={path.login}
        element={
          <UnauthenticatedGuard>
            <Login />
          </UnauthenticatedGuard>
        }
      />
      <Route
        path={path.home}
        element={
          <AuthenticatedGuard>
            <Dashboard />
          </AuthenticatedGuard>
        }
      />
      <Route
        path={path.transactions}
        element={
          <AuthenticatedGuard>
            <Transaction />
          </AuthenticatedGuard>
        }
      />
      <Route
        path={path.setting}
        element={
          <AuthenticatedGuard>
            <Setting />
          </AuthenticatedGuard>
        }
      />
      <Route path={path.notFound} element={<NotFound />} />
    </Routes>
  )
}
export default RoutesPath
