import React from 'react'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'

const ExportCSV = ({
  csvData,
  fileName,
  wscols,
  headerTitle,
  headerTranslate
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const Heading = headerTranslate

  const exportToCSV = (csvData, fileName, wscols) => {
    if (!csvData) {
      toast.warn('Không có dữ liệu để xuất!!', {
        position: 'top-center',
        autoClose: 3000
      })
      return
    }
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: headerTitle,
      skipHeader: true,
      origin: 0 //ok
    })
    ws['!cols'] = wscols
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: headerTitle,
      skipHeader: true,
      origin: -1 //ok
    })
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <button
      className="btn btn-success"
      type="button"
      onClick={(e) => exportToCSV(csvData, fileName, wscols)}
    >
      Xuất Excel
    </button>
  )
}

export default ExportCSV
